import "../../../../blocksuite/affine/block-table/src/table-cell.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/block-table/src/table-cell.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+1VwY7TMBC99yt8QdoVctV0KSzeE/wImtiT1ovriexJt13EvyM7MUnK0gUtSBw4JfY4b968eeMsP1X1/b6hlfiyEKKlaNmSVyKgA7YHvFsIAc5uvbSM+6iERs8Y0nZNwWBQomqPIpKzRhwgXEkJTWM9yj4sNTkK1+P5tOGgjahEeUtBGyklTKmhY0pbjEeWObcSkSFw2jxgYKvBlQBTe7f4ulgOZVRnZUAdyXWcU+zQbnesxCqDUzu8OWzK5oM1vFOiWq1epaWxsXVwUqJxeLwgxH0X2TYnqckzeh5DI6+bzEvKYblWc60Oa2k1eRlRkzcQTlkw3YVIQYmWbEn1KK03eFRiPeG7vm2P0wKrt/26Bv15G6jzpu/Cj0kZaodyh2AwfBxPG2ygczztWgBju6jEkKqmo4w7MPRwBlp3zKmQHMsA1IK2fCq6B/ClOUNErJbrKBAiSusldfyntVc7OmDIHfhOppqdWIJOZn+2Sb1e1hurgSnIoTMf8tfzaqvf6cCImIkAo7meEnxzwdaXnLya2eJntk5PaWxA3aNrct3ev0z0zd80fDF4qawMwH/D99o/a/jNv274t5nZizURYgutEuvUwxH8XQYfvHQzt9LNZScVpZ5yyiaN15jl9sLMvkym86n4VYsNzZN4QM9RCU8ep4zfZ8b5PpHVXBYNTl+lC0S8TmpeT4ZxMxcXMkiZZHyQAaN9zFWHHksOGp/T6f/7I1KdkfpLTZ61UE9jA9eBz1NUSxlnXE1GqYmZ9oXXGMWJHH36b522sRMtCQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cellContainerStyle = '_1bjmfo0';
export var columnIndicatorStyle = '_1bjmfo9 _1bjmfo8';
export var columnLeftIndicatorStyle = '_1bjmfob _1bjmfo9 _1bjmfo8';
export var columnOptionsCellStyle = '_1bjmfo1';
export var columnOptionsStyle = '_1bjmfo3';
export var columnRightIndicatorStyle = '_1bjmfoa _1bjmfo9 _1bjmfo8';
export var indicatorStyle = '_1bjmfo8';
export var rowBottomIndicatorStyle = '_1bjmfod _1bjmfoc _1bjmfo8';
export var rowIndicatorStyle = '_1bjmfoc _1bjmfo8';
export var rowOptionsCellStyle = '_1bjmfo4';
export var rowOptionsStyle = '_1bjmfo5';
export var rowTopIndicatorStyle = '_1bjmfoe _1bjmfoc _1bjmfo8';
export var threePointerIconDotStyle = '_1bjmfo7';
export var threePointerIconStyle = '_1bjmfo6';