import "../../core/src/modules/share-menu/view/share-menu/invite-member-editor/invite-member-editor.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/share-menu/view/share-menu/invite-member-editor/invite-member-editor.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VwXKbMBC9+yt06UxzkAeo46bK1whpga2FxEjC2O3k3zsCTESMcZzpAWNW0u7bfW9XW1Enye6QkL8bQiS6RvEzI4WC0+uGkA6lrxhJk+Rb+AxmKtGC8Gg0I8KottZhpeQNIy9Nf6gCLCs/P8VIGv6aI9hCmY6RCqUE/bp522wHBOkygo8hremCmSssNUUPtWNEgPZggzk3VoKlufHe1IykzYk4o1CSI7ffKeVFgRroMaOe51TiEcPu8f0UHIjWOmMZaQxefPap7YbUGi4l6rL/7p/9YBdGhVNXUeDkqQNhtOT2/BRlm/XZFkZ76vAPMJIlq55QGE0bi/UHPz8+V7Vloi5s0DMjvPVmRtYUYvcQMb9b57E4U2G0B+0ZcQ0XQHPwHYCOOPKm+QpBIwPD8T6PCehzD3SNCoX6cIvmiIvZ2cFeP017ulHez0kyk8TzIIkI0H4gORSqtEH06Xv+jGijoWeh9Qo1vBsiJOmou95U8BrVmRHUFVj0kVoiS87FobSm1ZKOi95y7RpuQfsYG2ON4gIqoyTYu5WL9sby+7msjbhlRrotl9i6xUaa1NTZcC78LsyeS9luSwZ103o6xpNQ8Fb5GdhtYUTresjjrlvdFnviwuMRYkcvKx2xmkM0CeMS1fxEL0Nzn9zqzSn8r3dZucqiPjCSRMu8X54KHOqVNVe0XOJl2SJNo/V+N19XYa2PTu66j3ZJjD5/aNr0GaVZpKGlioh5RcLcTpfTjtvtC7PgCvbiNTXhko/nOk7uG9ffZ3BfRp82nnKlTAfy3g0m0fFczVoA/tP9VXz0cxv3o1ds2bseO3A/G8zVythaq/DbP5rGuEouCQAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var InputContainer = 'cm004k7';
export var button = 'cm004kc';
export var buttonsContainer = 'cm004kb';
export var checkbox = 'cm004ke';
export var containerStyle = 'cm004k0';
export var footerStyle = 'cm004k4';
export var headerStyle = 'cm004k1';
export var iconStyle = 'cm004k2';
export var inlineMembersContainer = 'cm004k8';
export var manageMemberStyle = 'cm004k5';
export var memberListStyle = 'cm004k3';
export var menuTriggerStyle = 'cm004ka';
export var noFound = 'cm004kf';
export var planTagContainer = 'cm004kh';
export var roleSelectorContainer = 'cm004k9';
export var scrollbar = 'cm004kg';
export var searchInput = 'cm004k6';
export var sentEmail = 'cm004kd';