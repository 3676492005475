import "../../core/src/desktop/dialogs/setting/workspace-setting/storage/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/storage/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W0W7bMAx8z1cIBQq0QFU4TpdmKvayH9gHDMMgW7StzpYEik6cDf33wXISx2mcpm3eEkvk8U5HSve/HxuYxTZi/yaMKe1dKdeCZSU0TxPGnmtPOlvz1BoCQ4J5J1PgCdAKwLQ7ZKlzwzVB5QVLwRDg0+Rlcr/JOw1523Q8R7sSbDpcvfdkUebAHdocwXuuwKddkDXEvf4Lgi0l3nAus0wb4OF7429b9NSWFg/WlzEnaIh7SK1REtdhZwE6L0iwOHKB2aWoMlZJzLXhiSWylWBxm/80xUQiX6F0DjAw3da26EpLLCpAjlLp2gv2sPkq0z852tooPsK6lGtAvrevsEvAH0vAUnYitP+zsj2GQivVsuoLjQelTKPoen91dtwfI5K8rWUunWDT+VCrh5Nm+fK2K3wVaIbfqw2TeRQNzn8xxJyHrOf6qI97DHGbsyfrBIsfRpwVCCmNkJK2RrRgdbWnQtzFOamUNnn/4cAHi0MfnOEAh7radkCXTrCpa5i3pVYj4dp4reB7B94FDZgvhlbIUavABbXiBJUrJQHvOHrBEBxIupndsUqbSjY3s+j6jk0zvL09UKBH+BoQjrfBawsz5qzXnbQIpSS9hPZr7QG5hxJSEsxYA/sQ8sx513t6xOpbX826wdIjJAFhd6gL17zye3q8iHG/vL+8oO+B5VWAXWlFxbbRj+sqvYOUOErSNjTj2KHsWM4/PKuG0/oCB7O1e7yzO6E03kkEQwO3/VSS5MYpoL5dEdZw9YsN5doQH6GT1ETW7LXbXnoRxvBhuk9P8x4BOqftmkAm3pY1hSYIk2kzOXBwy+wN0u3ksk6mmtaCRacIdHC7vdP3aHkqFpiTVHxUnWPSZ8eMPrzhGLPJc2vyTLemsoakHlyLxdlvkQFOn0B/6to61pbhVuoXoCy189qHGgpNwMNt28689pHRPVEaPlLe88XfWi//ARWNnslUCgAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var blobCard = '_7xe32o9';
export var blobGridItemCheckbox = '_7xe32oe';
export var blobImagePreview = '_7xe32of';
export var blobManagementContainer = '_7xe32o7';
export var blobManagementControls = '_7xe32o3';
export var blobManagementName = '_7xe32o5';
export var blobManagementNameInactive = '_7xe32o6 _7xe32o5';
export var blobPreview = '_7xe32od';
export var blobPreviewContainer = '_7xe32oc';
export var blobPreviewFooter = '_7xe32oh';
export var blobPreviewGrid = '_7xe32o8';
export var blobPreviewInfo = '_7xe32oj';
export var blobPreviewName = '_7xe32oi';
export var empty = '_7xe32ob';
export var loadingContainer = '_7xe32oa';
export var spacer = '_7xe32o4';
export var storageProgressBar = '_7xe32o2';
export var storageProgressContainer = '_7xe32o0';
export var storageProgressWrapper = '_7xe32o1';
export var unknownBlobIcon = '_7xe32og';