import "../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VWwW7bMAy95yuIAQPqYQocN+ladwWG7bLbPmDYgbZoR6ssGZLsJBvy74Nlx7Fdp81OQSjy8ZF8ZLJMqLC7OoS/C4Cd4G4bwyoM3z8uAArcs85Uo7lhDLNMKGLEhdOmfQpaR5MLxSRlLgasnB4Yjci3A2uJnAuVd75zsFZwYp3bR4jWwTCsQ7s6LtPKMSv+0CTG2xO0FDwujotl24SVbwIXtpR4iCGTtG8wflfWiezAUq0cKReDLTEllpDbEanGA6XIFROOChtDSsqRGcBGHtZn3FHLfxOGE3arTemTyYbdtnOL1q0x1VKbSQWO9o6VRhRoGmpSm2Eptz5nj3Pf4iTacDLMIBeVjWFkjWFV7sFqKfg4URd0SgGQYPqcG10pzuaI7bbCkXf0FH1vzl0ZFx3NFd3RmmvY1Y0ASCtjG99Si8k81r43nTwT7ZwuYljdNVl7n433uZjOUqoVHyc88b+dK6o19vB3c+vW+4YtwAshzsoMIMcyhk4pvvnMCzQGpXcGy5nBr8dk4q2uyXhKb8y2jpjEAxk28PPBP2oyEg/BqMZlF2UoI0MqJeaEk9Qmahmd2q+0olGsrfOLI6gjJlKtzlMIZqq5BqBTzTD80/wNaD4ZF4ZSJ3QjZy2rQvXdPwm5E5XT54m8rrN7n258ljIshDzMHSyLynbPI41zyrCSbrjM4XIzv8515IcnhXq2LJE6ff7qQ4LLB6I7qv33Cy0dLuI1h2KGSR/wbXrQ7v9LpBPc703oCA0+tOKYNrD3eBi9vzwi6N+dQWVFKwmUEsJlZIHQEhOK6cq90q2JAEd3MQpHIsGfHB02tUosLfGnd85U9O7XmUKmTRGD0Q4d3TyEnPLgrdTj5flSEBcIpRHKedjl8H/BYCHaRQU4NkHNLyIKRQZqQbtSGwc3/qjB5ye4W4flPpgD6/UU9r9wx8XxH4Yv61CKCAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = 'bemswva';
export var container = 'bemswv0';
export var link = 'bemswv6';
export var linkPreview = 'bemswv8';
export var linkPreviewContainer = 'bemswv7';
export var linkPreviewRenderer = 'bemswv9';
export var linksContainer = 'bemswv4';
export var linksTitles = 'bemswv5';
export var showButton = 'bemswv3';
export var title = 'bemswv2';
export var titleLine = 'bemswv1';