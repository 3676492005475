import "../../core/src/modules/share-menu/view/share-menu/member-management/member-item.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/share-menu/view/share-menu/member-management/member-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7WTUVPCMAzH3/kUefFOHsptiIj1w3hlzVhY1+7SjoEe391bh8oEhQd8apr+m/ySNpPXdL4up4sygfcRgCZfG7WTkBvcvowgrkITYxbIWQns2s6tDK2soICVl5ChDcide934QPlOZM4GtEGCr1WGYomhRbSdolZak11JmNUxfks6FBLSJLnrtitVS0in3dl+NPlkS2/BdhS6vyoh7Uy3Qc6NayUUpHVPOaA6ApleB5I501Sngc7lOgr+EINnzjiWsFF8L4TKc7IoNlMRcBtEzVQp3o1jTmeD8PSGP7S9v4qatqCAIr6BBOtaVvVvFcf43wdoDNWe/ABwdhHQY+asvgpx6/8D8fHmPYxv6wsmW0pIhskmmrxaGryY9KAbD67P47XWsRZLRlVKiIvoPAPhUxT+nJzDXjCtihDRAJaONbJgpanxX8r48w/26d+9pged3WKfaJYMu7D4q3ofVGi8QGbHw+qfz49SZF30rGcHef8BxXzlKLIEAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var memberContainerStyle = '_16jk28k1';
export var memberEmailStyle = '_16jk28k4';
export var memberInfoStyle = '_16jk28k2';
export var memberItemStyle = '_16jk28k0';
export var memberNameStyle = '_16jk28k3';
export var memberRoleStyle = '_16jk28k5';
export var menuTriggerStyle = '_16jk28k7';
export var planTagContainer = '_16jk28k9';
export var remove = '_16jk28k8';
export var tooltipContentStyle = '_16jk28k6';