import "../../core/src/desktop/dialogs/setting/workspace-setting/members/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/members/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWSW7bMBTd+xREgALJgoasZnCYXS/QRU9Ai1/STyiSIL+nFr17QWqwpCQ20qYricOf33vSssqKl2eTsV8LxpwNSGiNYB60JNzB04Ix62SBdBQsW97GdSN9hYaTdYLlt+7wtPi9WHZuhJAlgU/eCmsIDAl2dRXN9qioFmyVZV/isgasajqtT7HlJli9pRRbQ0mCZfE1xUtvxdYH6wUzlrjU2u5BjXJYtaVIpdBUgqUEGdtYr8BzLxVug2CrvNuWxUvl7dYowXbSX3MuyxIN8F3OtTyC56cb3HlspD/enPwJtnIHFqxG9Y45moAKvrXRW6NkrzA4LY+ClRpSJvHJFXoo2i4UVm8bE0+et4GwPPKhn8HJAvgGaA9gRpXnqfJKDnP5QJjBydfkZLBDo2M9vbnUWBmOBE0QrABD4Ee2t8m2tIZ4wJ8wa2naP4RUfmF1nOC8ZwQH4tC4WgbsLnazdhbbYAP+WmjM62zzHeV012HxzXiBJG0DB+9tHMtgdD9tQl/9q0n0HZg1Jt7/QdLTpVIDFNaoHlNpcl1BA37X7fpyU4fkH6YEyKJLlrHVfeupJ97d+h2IzIh6fuQPora7jvAfIlMy+74Dr+WYUQND1xNheRDG0rXQMhC3Jaejg5sUswPDxhLZRrD7idU6Xenqye+yD3KiOwm1R/PSac8EfL2KdJu+bWyeTZJ4bEkRPVXe7gVbJVHdgS91XNaoFKRoCRKnA9AaXcCQZlIjAU/Ej7q399JdREVoxqh4XDowERHn+DDn38g6ceRvuCQvi0LK9HxSY+39nN4lXevJkOeTmW0+Rcim7P4fWU+RVrytWu/DOwlOD+KTYIxcqn9yOf5TmO31hJ2VAK8+3p0Q/AE1I6RWqAgAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var confirmAssignModalContent = 'g0ckjnc';
export var confirmInputContainer = 'g0ckjnd';
export var errorStyle = 'g0ckjn5';
export var fakeWrapper = 'g0ckjn0';
export var goUpgrade = 'g0ckjn4';
export var goUpgradeWrapper = 'g0ckjn3';
export var importButton = 'g0ckjne';
export var localMembersPanel = 'g0ckjn2';
export var memberContainer = 'g0ckjn8';
export var memberEmail = 'g0ckjnb';
export var memberListItem = 'g0ckjn7';
export var memberName = 'g0ckjna';
export var membersFallback = 'g0ckjn6';
export var membersPanel = 'g0ckjn1';
export var roleOrStatus = 'g0ckjn9';