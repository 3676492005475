import "../../core/src/modules/share-menu/view/share-menu/index.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/share-menu/view/share-menu/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VwY6bMBC971f4UmlzcEQSQrOutN/Rq8EDcWJsZBtCWvXfK2wgkEA2VD2FjGbevJl5M16XJ7G5HAL0+w0hxk0h6JWgVED94w0hKngmMbeQG4ISkBZ0Y06VtNjwX0BQRfU7xjRNuQTs7fmq97kAz46WoCgIGptonI6tbbstXI6CMsZlRlCAQm/JaEH895+3tee3mebX/GLGNSSWK0lQokSZyx7jMMLYOowLZ/ZI0O4j8MlyLntKu01npHVv3Ef3RDfbEe5uEnfOOxx6b/b33mFRo47FqTSWp1ecKGlBWoJMQRPAMdgLgBxg7h3mEKMJt5pKw31jqBAoWO9M35pgRGqPPhHjFUm5NhYnRy6Yg5yc/yDKVNmonMhn7lrn//f+kfdVmuGLbkjEGugZN4YvVVWb1aOC2j4lSih9F1RtsYXaYgOJkozqqwt3JlcUQQJSe6e/Md3v05rrag2Cb4slePCDUt1YNAhqeQUDl4+lSn9QyW1P76hOEKL/Ya9ih7H4Juybm9CDJA5kZpJxaa2SGLRWejUIYmPdN5vmub1+ymKlGWisKeOlIV10TJNzplUpGZ6hJOgVNB74jYU2vCqHdh+fjh1GexRGM1U8DNtNCiR7UuItL42NEqUFX7i1KifIHWbtmQ4Hkk7elFd29HHKM3vrDlSqdE78p6AWfr7jsKhXM89Aij6bIyificXtuODyvLoLZLxqwtuTtSQ6W/Q63ng/Vfxx3ODopt3+ORq/HPzrPWsn8PpBHPELR/xOywXQrvk/pvft+QuAypuzkQgAAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var button = 'ujl1w8h';
export var buttonContainer = 'ujl1w8g';
export var cloudSvgContainer = 'ujl1w8e';
export var columnContainerStyle = 'ujl1w89';
export var containerStyle = 'ujl1w87';
export var content = 'ujl1w81';
export var descriptionStyle = 'ujl1w86';
export var disableSharePage = 'ujl1w8c';
export var exportContainerStyle = 'ujl1w8a';
export var exportItemStyle = 'ujl1w85';
export var generalAccessStyle = 'ujl1w8j';
export var headerStyle = 'ujl1w80';
export var indicatorContainerStyle = 'ujl1w88';
export var labelStyle = 'ujl1w8b';
export var localMenuStyle = 'ujl1w83';
export var localSharePage = 'ujl1w8d';
export var menuStyle = 'ujl1w82';
export var menuTriggerStyle = 'ujl1w84';
export var shareLinkStyle = 'ujl1w8f';
export var shortcutStyle = 'ujl1w8i';