import "../../../../blocksuite/presets/src/fragments/outline/card/outline-preview.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/presets/src/fragments/outline/card/outline-preview.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62UzZLaMAzH7zyFL52Bg5lAWbo1M32FPoMSK4m6ju2xTYDt8O4dJyEkfHSznR4t6Sdbf0leulNYJTZhv2eM5UYHnkNF6iRYDW7OOeQ5aeQDz2I3Y+xAMpSCrZLkSzym5sg9vZMuBEuNk+h4ao7RY0HKxry1R/ZqG9uhpIDcW8hQMG0ODmw0S/JWwUmwXGET92vvA+UnnhkdUAfBfAAXogcUFZpTwMoLlqEO6KK5ACvaS86zZVeYKE2Nrikv2ztvnGDW0IVIIXsrnNlreVNwveYKTrGOPoI3mX7W6BREFc6zJWSBamQ/2HIoY2ZUvOY2X8Bj4FjZEjz5xeiN2oS5KMHPvQW9WDRZejm00TiIXo29F7GeSHKn4dXV9XC9brtSIhVluJ4n9HTTRX5GRI+Z0RJcO0ddYgeS9r5P+ERAyozm1lHV0r0i67+p3kCSPKQKh9DXBnoyirHRuTIHwUqSEnW0Ne27OlApsp58dMUeCLbaXVbI0zs+WiBfNUWraLiVe3zun7m5Luahi9gkyaALXGEesfHYv9xj20dYMmC2/8B8m8hsRq97nUitxlp8n4ptRxhMxNbJCEunYuPasv82V31KeX1JO1mkS3QUBguUmhBMJViyfLFH5o0iOZ4/STXFwGZLFne/cOoQ3tpDM9EVuII0d91MjirEDz+5B+uWfwLqNJGYGQeBjBbtyoTSmX1R7mbnP75w5Qa2BgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var icon = 'ryt10p1';
export var iconDisabled = 'ryt10p2';
export var linkedDocPreviewUnavailable = 'ryt10pe';
export var linkedDocText = 'ryt10pd';
export var linkedDocTextUnavailable = 'ryt10pf';
export var outlineBlockPreview = 'ryt10p0';
export var subtypeStyles = {title:'ryt10p5',h1:'ryt10p6',h2:'ryt10p7',h3:'ryt10p8',h4:'ryt10p9',h5:'ryt10pa',h6:'ryt10pb'};
export var text = 'ryt10p3';
export var textGeneral = 'ryt10p4';
export var textSpan = 'ryt10pc';