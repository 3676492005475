import "../../../../blocksuite/presets/src/fragments/outline/card/outline-card.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/presets/src/fragments/outline/card/outline-card.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVwY7aMBC98xUW0krLwauQQst6td/QXqtqVU3wJLh17Mh2AmnVf6/iJKwTvGiR2hMwNm/ezHszfvi+ropys8sT8ntBSKWtcEIrRgxKcKLBpwUhXNhKQstILvHUBUCKQlHhsLSM7FE5NF34R22dyFu618qhcuFRpk/Uil9CFYxk2nA0NNOnp8WfxcOZwTcODqh14Gr7vOQGikKoYvkyMBMdFsUGlbOMKK08N13BXriWkeRhG4XTxkEm8XnpTI0jGHDuqaTViSTVlMf6SieOgrsDI+skueur8pUY4KK2jGwq3519baw2jHDMoZauC9UWDbUoce9G6mFGdtANGp83g/3PwuhacUYaMPeUQp4LhbRJqYS269v5BvV/+9ygkdCurjWzT418+UJmdf6ffIF4/zDfRO3HSf50qmtCdr0WM8FKoegBRXFwjKTDlbO7R0u94e4CKjbCvsPOaSDq6IjCQPYuk87qmg3gJUAjrMiEFK59XiIvUKK1E5gImbM9b4f70ANp2eHMVXR4cpQL2xXkRYuPbpB1Eyvy9i0TIG49omfiUcL/51q5TjycUe/jpaccKy0zoDj1J6szznEw0zZJupjsLo4GW29mm+WjZ3U+7q3UVcvIOlgnTlf+lHCwB+QzFv2dVxrOgLK5NiXrv0pw+PV+m9xNB/TTtMej11/XHGRWy9r5qOkZDmb3fOjH/scwYj3J3TSWaed0eQ5fm6NhVQZarNMgdK2t6cXCHsz9Vq0XQ7OL+y2+zYN1FHOFTx3IES06yP0Yzz1/kfzn5kofA0jwkL5vOZRCtlFjg7LD8eqNF+n6RFdGlGBmax8ie2UY98m9bPamhma78ORNT4TuX4cvoFCuIiqGC/sAXB9nmH2Qpqtraz3QZ3dh3JuWyGU35+6OjcGmH4ObJA67P7wyB318iY7GXxx52dUGCgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cardContent = '_1pgm48fa _1pgm48f3';
export var cardHeader = '_1pgm48f2';
export var cardPreview = '_1pgm48f1';
export var currentModeLabel = '_1pgm48f9';
export var displayModeButton = '_1pgm48f8';
export var displayModeButtonGroup = '_1pgm48f7';
export var divider = '_1pgm48f6';
export var headerIcon = '_1pgm48f4 _1pgm48f3';
export var headerNumber = '_1pgm48f5 _1pgm48f3';
export var modeChangePanel = '_1pgm48fb';
export var outlineCard = '_1pgm48f0';